export const reAssignOrder = {
    action: "mdi-autorenew",
    title: "ReAssignOrders",
    permission: "order-re-assign",
    items: [
        {
            prefix: "O",
            title: "ReAssignOrders",
            link: "/re-assign-orders",
            permission: "order-re-assign-index",
        },
        {
            prefix: "O",
            title: "AboutSignature",
            link: "/about-signature",
            permission: "order-re-assign-signature",
        },
        // {
        //     prefix: "O",
        //     title: "BulkSignature",
        //     link: "/bulk-signature",
        //     permission: "order-re-assign-bulk-signature",
        // },

    ]
}


export const lockOrder = {
    action: "mdi-lock-reset",
    title: "LockOrders",
    permission: "order-lock",
    items: [
        {
            prefix: "O",
            title: "LockOrders",
            link: "/lock-orders",
            permission: "order-lock-index",
        },
        {
            prefix: "O",
            title: "AboutSignature",
            link: "/lock/about-signature",
            permission: "order-lock-signature",
        },
        // {
        //     prefix: "O",
        //     title: "BulkSignature",
        //     link: "/lock/bulk-signature",
        //     permission: "order-lock-bulk-signature",
        // },

    ]
}
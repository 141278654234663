// Axios setup
import VueAxios from "vue-axios";
import authHeader from "../services/auth-header";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";
import { CURRENT_URL_BACK, CURRENT_URL_FRONT, FRONT_URLS, CURRENT_Gov_URL_BACK, TAFWEEJ } from "./url"
const axios = require("axios");
Vue.use(VueAxios, axios);

Vue.prototype.$baseURL = CURRENT_URL_BACK;
Vue.prototype.$frontURL = CURRENT_URL_FRONT;
Vue.prototype.$arafatFrontURL = FRONT_URLS.ARAFAT;
Vue.prototype.$muzdalifaFrontURL = FRONT_URLS.MUZDALIFA;
Vue.prototype.$govFrontURL = FRONT_URLS.GOV;
Vue.prototype.$complaintFrontURL = FRONT_URLS.COMPLAINT;

Vue.prototype.$baseGovURL = CURRENT_Gov_URL_BACK;
Vue.prototype.$tafweejUrl = TAFWEEJ
const ENDPOINT_BASE_API = CURRENT_URL_BACK + "api";

window.axios = require("axios");
axios.defaults.baseURL = ENDPOINT_BASE_API;
axios.interceptors.request.use(function (config) {
  config.headers = authHeader();
  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error)

    if (error.response && error.response.status == 401) {
      console.log(router)
        ; store
          .dispatch("auth/logout")
          .then(() => {
            const prevPath = router.history._startLocation ?? undefined;
            saveRedirectionIntoStorage(prevPath);
            router.push({ path: `/login` });
          })
          .catch((err) => console.log(err));
    }
    return Promise.reject(error);
  }
);

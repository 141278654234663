import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export default {
    path: "/term-conditions",
    component: DashboardLayout,
    name: "CMS-Pages",
    children: [{
        path: "/",
        name: "Terms",
        component: () =>
            import("@/views/Terms/Show.vue"),
        meta: {
            groupName: "Terms",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "terms-index",
        },
    },

    {
        path: ":id",
        name: "Update-Terms",
        component: () =>
            import("@/views/Terms/Edit.vue"),
        meta: {
            groupName: "Terms",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "terms-update",
        },
    },



    ],

}
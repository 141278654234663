
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";

// form
const FormPage = () => import("@/views/TempLicenseOrder/formTamplate/Show.vue");
const NewForm = () => import("@/views/TempLicenseOrder/formTamplate/New.vue");
const EditForm = () => import("@/views/TempLicenseOrder/formTamplate/Edit.vue");

export const TempLicenseFormPage = {
    path: "/",
    component: DashboardLayout,
    name: "TamplateForm",
    children: [{
        path: "/temp-licenses-forms/create",
        name: "NewTempLicenseForm",
        component: NewForm,
        meta: {
            groupName: "Form",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "temp-tamplate-form-create",
        },
    },
    {
        path: "/temp-licenses-forms/update/:id",
        name: "UpdateTempLicenseForm",
        component: EditForm,
        meta: {
            groupName: "Form",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "temp-tamplate-form-update",
        },
    },

    {
        path: "/temp-licenses-forms",
        name: "TempLicenseFormPage",
        component: FormPage,
        meta: {
            groupName: "Form",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "temp-tamplate-form-index",
        },
    },
    ],
};



const LicenseOrderPage = () => import("@/views/TempLicenseOrder/Show.vue");
const NewLicenseOrder = () => import("@/views/TempLicenseOrder/New.vue");
const EditLicenseOrder = () => import("@/views/TempLicenseOrder/Edit.vue");
const ViewLicenseOrder = () => import("@/views/TempLicenseOrder/View.vue");
export const TmpLicenseOrderPage = {
    path: "/",
    component: DashboardLayout,
    name: "TmpLicenseOrder",
    children: [
        {
            path: "/temp-licenses-order/create",
            name: "NewTmpLicenseOrder",
            component: NewLicenseOrder,
            meta: {
                groupName: "TmpLicenseOrderPage",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "temp-licenses-order-create",
            },
        },
        {
            path: "/temp-licenses-order/update/:id",
            name: "EditAssignTmpLicense",
            component: EditLicenseOrder,
            meta: {
                groupName: "TmpLicenseOrderPage",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "temp-licenses-order-update",
            },
        },
        {
            path: "/temp-licenses-order",
            name: "TmpLicenseOrderPage",
            component: LicenseOrderPage,
            meta: {
                groupName: "TmpLicenseOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "temp-licenses-order-index",
            },
        },

        {
            path: "/temp-licenses-order/view/:id",
            name: "TmpLicenseOrderView",
            component: ViewLicenseOrder,
            meta: {
                groupName: "TmpLicenseOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "temp-licenses-order-view",
            },
        },
    ],
};
export const order_temp_license = {
    action: "mdi-clipboard-account-outline",
    title: "TmpLicense",
    permission: "order",
    items: [

        {
            title: "TempTamplateForm",
            prefix: "K",
            link: "/temp-licenses-forms",
            permission: "temp-tamplate-form-index",
        },

        {
            prefix: "T",
            title: "TmpLicenseOrder",
            link: "/temp-licenses-order",
            permission: "temp-licenses-order-index",
        },


    ],
}